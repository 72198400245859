import React, {useContext} from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span, ButtonOutline, Box } from "../../components/Core";

import GlobalContext from "../../context/GlobalContext";


const Link = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const VideoWrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
`
const Video = styled.iframe`
    position: absolute;
    border: 0;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
`

const videoLinks = [
    {
        link: "https://www.youtube.com/embed/Vu2HuiISazc",
        title: "React Native & Firebase Auth quick setup",
        description: "We create an iOS app with Email + Pass and Google Signin. This is all build ligtning fast using Firebase!"
    },
    {
        link: "https://www.youtube.com/embed/--micLqNGII",
        title: "Mac Dev setup in 1 minute",
        description: "Setting up a brand new Mac for building some sweet stuff."
    }
    
    

]

const Videos = ({ hero = true, ...rest }) => {

  return (
    <>
      <Section {...rest}>
        <Container fluid>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Title className="my-4">
                    Videos
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      
                    `}
                  >Our videos show you how we use tech to get stuff done quicker and neater. 
                  We call these clips Swagg Bites - being short in length and sharing a bite sized concept in a digestable format.<br/> 
                  Want to see us do a video on a particular thing?<br/> 
                  Reach out to us with your suggestions, or leave a comment on the videos!
                  </Text>
                </Box>
              </Col>
            </Row>
        
            <Row className="mt-5 d-flex justify-content-center">
            {
                videoLinks.map(video => {
                    return (
                        <Col xs="12" lg="8" className="mb-5 px-sm-5">
                            <VideoWrapper>
                                <Video src={video.link} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                                
                            </VideoWrapper>
                            <Text variant="tag" className="mt-3">SWAGG BITES</Text>
                            <Title variant="cardBig" className="mt-1">{video.title}</Title>
                            <Text variant="p" css={`max-width: 750px;`}>{video.description}</Text>
                        </Col>
                    )
                })
            }
            </Row>
        </Container>
      </Section>
    </>
  );
};

export default Videos;
